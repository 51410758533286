import { useState, useEffect, useContext } from 'react';
import { Grid, makeStyles } from "@material-ui/core";
import ClipsEnabled from "./ClipsEnabled";
import ClipsSignUp from "./ClipsSignUp";
import { AppDataContext } from 'Context';
import { useAlert } from 'react-alert';
import { slideoutTransitionDelay } from 'config/ui';

const useStyles = makeStyles((theme) => ({
  outerContainer: {
    borderRadius: 10,
    '&.padding': {
      padding: 20,
    },
    '&.shadow': {
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    }
  },
}));

const ClipsElement = (props) => {
  const { 
    showCloseButton, 
    showDoNotShowAgainButton,
    showEnabledClips,
    hideBoxShadow,
    noPadding,
    hideLogo,
    useSingleLineHeader,
    // Optional props to control the visibility of the clips element from a parent component
    showElementIn,
    onDismiss,
    ...restProps
  } = props;
  const classes = useStyles();

  const alert = useAlert();

  const {
    appData: { user, doNotShowClipsPrompt },
    reloadProfile,
    setDoNotShowClipsPrompt,
  } = useContext(AppDataContext);

  const enableClips = process.env.REACT_APP_ENABLE_CLIPS === "true";
  const [showElement, setShowElement] = useState(enableClips);

  useEffect(() => {
    if (!doNotShowClipsPrompt) {
      setShowElement(true);
    }
  }, [doNotShowClipsPrompt])

  useEffect(() => {
    if (showElementIn != null) {
      setShowElement(showElementIn);
    }
  }, [showElementIn])

  const onSignUpSuccess = () => {
    alert.success(null, { isClipsConnectSuccess: true, offsetError: true, timeout: 3000 + slideoutTransitionDelay });
    reloadProfile();
  }

  const handleDismiss = () => {
    setShowElement(false)
    onDismiss && onDismiss();
  }

  return showElement && (showEnabledClips || !user.clips_enabled) && !(doNotShowClipsPrompt && showDoNotShowAgainButton) && (
    <Grid container className={`${classes.outerContainer} ${hideBoxShadow ? '' : 'shadow'} ${noPadding ? '' : 'padding'}`} {...restProps}>
      { user.clips_enabled ? (
        <ClipsEnabled />
      ) : (
        <ClipsSignUp
          onDismiss={handleDismiss}
          onDoNotShowAgain={() => setDoNotShowClipsPrompt(true)}
          onSignUpSuccess={onSignUpSuccess}
          showCloseButton={showCloseButton}
          showDoNotShowAgainButton={showDoNotShowAgainButton}
          hideLogo={hideLogo}
          useSingleLineHeader={useSingleLineHeader}
        />
      )}
    </Grid>
  )
}

export default ClipsElement;