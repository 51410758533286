import { Grid, Switch } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { styles } from './styles';

const useStyles = makeStyles(styles);

export const SettingsToggle = (props) => {
  const { checked, onChange, text, disabled } = props;

  const classes = useStyles();

  return (
    <Grid item xs={12} container direction='row' alignItems='center'>
      <Grid item xs={10} container direction='column' className={`${classes.switchLabelContainer} ${disabled ? classes.disabledOption : ''}`}>
        <span>{text}</span>
      </Grid>
      <Grid item xs={2} container justifyContent="flex-end" alignItems='center'>
        <Switch
          classes={{
            root: classes.switchRoot,
            track: `${classes.switchTrack} ${disabled ? classes.disabledSwitch : ''}`,
            checked: `${classes.switchChecked}`,
            switchBase: `${classes.switchBase}`,
            thumb: `${disabled ? classes.disabledSwitchThumb : ''}`,
          }}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  )
}

export default SettingsToggle;