import sliderLock from 'assets/images/slider-lock.svg';

const styles = (theme) => ({
  container: { height: '100%', minHeight: 630 },
  innerContainer: {
    height: '100%',
    marginBottom: 80,
  },
  sectionContainer: {
    paddingTop: theme.spacing(8),
    '& .MuiDivider-root': {
      backgroundColor: '#EEEEEE',
      height: 1,
      margin: '12px 0'
    },
  },
  firstDraftHeader: {
    '& img': {
      width: 27,
      height: 27,
    }
  },
  firstDraftContainer: {
    padding: '20px 0',
  },
  infoLink: {
    cursor: 'pointer',
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: theme.common.white },
  firstSectionContainer: { paddingTop: theme.spacing(2) },
  secondSectionContainer: { paddingTop: theme.spacing(1) },
  changeHandleLink: {
    color: theme.grey[900],
    textDecoration: 'underline',
    '&:hover': {
      color: theme.common.black,
      textDecoration: 'underline',
    },
  },
  asqmeHandleContainer: {
    marginBottom: 16,
  },
  asqmeUrl: {
    fontFamily: 'Figtree-Bold',
    fontSize: '0.9rem',
    '& span': {
      fontFamily: 'Figtree-Bold',
      fontSize: '0.9rem',
      color: theme.blue,
    },
    '&.disabled': {
      opacity: 0.5,
    },
  },
  chainIcon: {
    marginLeft: theme.spacing(1),
    width: theme.spacing(3.5),
    cursor: 'pointer',
    '&.disabled': {
      cursor: 'not-allowed',
    },
  },
  autoTranslateContainer: {
    marginBottom: 16,
  },
  secondSectionClassName: {
    padding: `${theme.spacing(2.5)}px 0 ${theme.spacing(1)}px`,
  },
  emailContainerInner: {
    '& .MuiGrid-item': {
      paddingTop: 0,
      paddingBottom: 0,
    }
  },
  tipjarLinkInfo: { cursor: 'pointer' },
  switchLabelContainer: {
    fontSize: '1rem',
    fontFamily: 'Figtree-Regular',
    '& span:nth-child(1)': {
      fontFamily: 'Figtree-Bold !important',
    },
    '& span:nth-child(2)': {
      marginTop: 4,
    },
  },
  disabledOption: {
    color: '#666666',
  },
  disabledSwitch: {
    opacity: '0.5 !important',
  },
  disabledSwitchThumb: {
    backgroundColor: '#454545',
    backgroundImage: `url(${sliderLock})`,
    backgroundSize: 'contain',
  },
  switchRoot: {
    width: 50,
    padding: `7px 0 7px 7px`,
    '& .MuiSwitch-colorSecondary.Mui-checked:hover': {
      backgroundColor: 'transparent',
    }
  },
  switchTrack: {
    borderRadius: 12,
    '&.disabled': {
      backgroundColor: theme.grey[400],
    },
  },
  switchChecked: {
    '&.MuiSwitch-colorSecondary': {
      color: theme.common.white,
      '& + .MuiSwitch-track': { backgroundColor: theme.blue, opacity: 1 },
    },
  },
  switchBase: {
    '&.disabled': {
      color: theme.grey[400],
    },
    '&:hover': { backgroundColor: 'transparent' },
  },
  connectionsTextContainer: {
    paddingBottom: '0px !important',
    marginBottom: 2,
  },
  connectionsText: {
    color: theme.grey[600],
    fontSize: '0.9rem',
    fontFamily: 'Figtree-Bold',
  },
  email: {
    fontFamily: 'Figtree-Bold',
    fontSize: '1rem',
  },
  editEmailIcon: {
    cursor: 'pointer',
    marginTop: theme.spacing(0.3),
  },
  stripeImg: {
    width: 64,
    height: 24,
  },
  translationText: {
    fontFamily: 'Figtree-Bold',
    fontSize: '0.9rem',
  },
  select: {
    '& .MuiSelect-icon': {
      color: theme.blue,
      width: theme.spacing(3),
    },
  },
  thankBankContainer: {
    marginBottom: 20,
  },
  thankBankPreviewButton: {
    height: 20,
    '& .MuiButton-label': {
      fontFamily: 'Figtree-SemiBold',
      fontSize: '1rem',
      color: 'white',
      textTransform: 'none',
      textDecoration: 'underline',
    },
    '& .MuiTouchRipple-root': {
      display: 'none',
    }
  }
});

const headerStyles = (theme) => ({
  itemHeader: {
    padding: theme.spacing(1),
    backgroundColor: theme.blue,
  },
  leftHeaderText: {
    color: theme.common.white,
    fontSize: '1.25rem',
    '& span': { // superscript
      fontFamily: 'Figtree-SemiBold',
      fontSize: '0.75rem',
      verticalAlign: '0.4rem',
      lineHeight: 0,
    }
  },
  rightItem: {
    paddingLeft: theme.spacing(2),
  },
  rightItemRightAlign: {
    display: 'flex',
    height: '100%',
    flex: 1,
    justifyContent: 'end',
    alignItems: 'end',
    paddingBottom: 4,
  }
});

const cardStyles = (theme) => ({
  card: {
    border: `2px solid ${theme.grey[300]}`,
    borderRadius: 5,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    '&.premium': {
      border: `2px solid ${theme.premiumYellow}`,
      borderLeft: `5px solid ${theme.premiumYellow}`,
    },
  },
  leftTopHeaderText: {
    fontFamily: 'Figtree-Bold',
    fontSize: '0.9rem',
  },
  secondSection: {
    padding: `${theme.spacing(3)}px 0 ${theme.spacing(2)}px`,
  },
});

export { cardStyles, headerStyles, styles };
